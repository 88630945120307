import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';
import DrRakeshDetails from './Dr.Rakesh Kumar/DrRakeshDetails';

export const DrRakesh = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title="Dr.Rakesh Kumar" subtitle="Details" />
    <DrRakeshDetails />
    <HomeFooter/>
    </>
  )
}
export default DrRakesh;