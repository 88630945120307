import React from 'react'

import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import HomeFooter from '../Home/Home/HomeFooter/HomeFooter';
import Founder from './Founder';

export const founderDetail = () => {
  return (
    <>
            <HomeHeader/>
            <CommonPageHeader title="Mr.Rohit Bilwar" subtitle="Details" />
            <Founder/>
            <HomeFooter/>
        </>
  )
}
export default founderDetail;