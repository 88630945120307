import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import Physiotherapy from '../Physiotherapy/physiotherapy'
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';

export const PhysiotherapyDetail = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title="PHYSIOTHERAPY" subtitle="Details" />
    <Physiotherapy/>
    <HomeFooter/>
</>
  )
}
export default PhysiotherapyDetail;