import React from "react";
import { Link } from "react-router-dom";
import useGlobalContext from "../../hooks/useGlobalContext";
import { FaPlay } from "react-icons/fa";

const HomeHeroSingleSlide = ({ bg_className }) => {
  const videoPopup = useGlobalContext();
  const { setIsOpen } = videoPopup;
  return (
    <>
      <div
        className={`single-slider slider-height d-flex align-items-center slider_bg_${bg_className}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="hero-text">
                <div className="hero-slider-caption ">
                  <h5>We are here for your care.</h5>
                  <h1>Best Care & Better Doctor.</h1>
                  <p>
                    “Sometimes, the best thing we can do for our patients is to
                    tell them what the best behavior is and then negotiate
                    something they can live with.”{" "}
                  </p>
                </div>
                <div className="hero-slider-btn">
                  <Link
                    to="/BookAppoinment"
                    className="primary_btn btn-icon ml-0"
                  >
                    <span>+</span>Make Appointment
                  </Link>

                  <button
                    onClick={() => setIsOpen(true)}
                    className="play-btn popup-video "
                  >
                    <FaPlay />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHeroSingleSlide;
