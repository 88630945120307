import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';
import DrAnoopDetails from './Dr.Anoop Patel/DrAnoopDetails';

export const Anoop = () => {
  return (
    <>
     <HomeHeader/> 
    <CommonPageHeader title="Dr.Shailja Gupta" subtitle="Details" />
    <DrAnoopDetails />
    <HomeFooter/>
</>
  )
}
export default Anoop;