import React from 'react'
import { Link } from 'react-router-dom';
import { GiMicroscope } from "react-icons/gi";
import { FaXRay } from "react-icons/fa6";
import { TbPhysotherapist } from "react-icons/tb";
import { TbDisabled } from "react-icons/tb";
import { FaRegHospital } from 'react-icons/fa';
import {FaVenus} from 'react-icons/fa';
import Happy from'./privantiveImagee_746x465.jpg'

import care from '../care.png';
import service from '../lifesupport.png';

export const Preventivemedis = () => {
  return (
    <>
         <div className="service-details-area pt-120 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 col-lg-8">
                     <article className="service-details-box">
                        <div className="service-details-thumb mb-80">
                           <img className="img" src={Happy} alt="650*406" />
                        </div>
                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">GENERAL MEDICINE</h5>
                              <h1>We are here to help when you need us.</h1>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                        <p>Welcome to the General Medicine department at our hospital, where we prioritize your overall health and well-being. Our dedicated team of experienced physicians and healthcare professionals is committed to providing comprehensive and personalized medical care.</p>

                           <p>In the General Medicine department, we specialize in the prevention, diagnosis, and treatment of a wide range of medical conditions. Our physicians are equipped with the latest medical knowledge and diagnostic tools to address both common ailments and complex health issues. We offer a holistic approach to healthcare, focusing on preventive measures, early detection, and effective management of various medical conditions.</p>

                           <p>Our services include routine check-ups, vaccinations, and management of chronic diseases such as diabetes, hypertension, and respiratory conditions. We also conduct thorough medical evaluations to identify any underlying health concerns and provide timely interventions.</p>

                           <p>With a patient-centered approach, we prioritize clear communication and collaboration with our patients. Our goal is to empower you with the knowledge and tools to make informed decisions about your health. We understand that each patient is unique, and we tailor our treatments to meet individual needs.</p>

                           <p>
                           At our General Medicine department, you can expect compassionate care, prompt diagnosis, and evidence-based treatments. Our commitment to excellence extends to creating a comfortable and supportive environment for all our patients.
                           </p>

                           <p>
                           Whether you require preventive care or are seeking treatment for a specific medical condition, our General Medicine department is dedicated to promoting your overall health and ensuring you receive the highest quality of medical care. Your well-being is our priority, and we look forward to serving you on your healthcare journey.
                           </p>

                        </div>
                        <div className="service-details-feature fix mb-35">
                           <div className="ser-fea-box f-left">
                              <div className="ser-fea-icon f-left">
                                 <img src={care} alt="care" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Lifestyle support</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Personalized Nutrition Guidance,</li>
                                    <li><i className="fas fa-check"></i>Fitness and Exercise Programs,</li>
                                    <li><i className="fas fa-check"></i>Behavioral Health Support,</li>
                                    <li><i className="fas fa-check"></i>Sleep Improvement Strategies.</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="ser-fea-box f-left me-0">
                              <div className="ser-fea-icon f-left">
                                 <img src={service} alt="" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Lifestyle support</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Personalized Nutrition Guidance,</li>
                                    <li><i className="fas fa-check"></i>Fitness and Exercise Programs,</li>
                                    <li><i className="fas fa-check"></i>Behavioral Health Support,</li>
                                    <li><i className="fas fa-check"></i>Sleep Improvement Strategies.</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                           
                     </article>
                  </div>
                  <div className="col-xl-5 col-lg-4">
                     <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">More Services</h3>
                        </div>
                        <div className="more-service-list">
                           <ul>
                              <li>
                                 <Link to="/Orthopedic">
                                    <div className="more-service-icon fs-1 text-danger"><TbDisabled /></div>
                                    <div className="more-service-title">Orthopedic Care</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Gynecologist">
                                    <div className="more-service-icon fs-1 text-danger"><FaVenus /></div>
                                    <div className="more-service-title">Gynecology Care</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Surgery">
                                    <div className="more-service-icon fs-1 text-danger"><FaRegHospital/></div>
                                    <div className="more-service-title">General Surgery</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Radiology">
                                    <div className="more-service-icon fs-1 text-danger"><FaXRay/></div>
                                    <div className="more-service-title">RADIOLOGY</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Pathology">
                                    <div className="more-service-icon fs-1 text-danger"><GiMicroscope /></div>
                                    <div className="more-service-title"> PATHOLOGY DEPARTMENT</div>
                                 </Link>
                              </li>      
                              
                              <li>
                                 <Link to="/Physiotherapy">
                                    <div className="more-service-icon fs-1 text-danger"><TbPhysotherapist /></div>
                                    <div className="more-service-title">PHYSIOTHERAPY </div>
                                 </Link>
                              </li>                             
                           </ul>
                        </div>
                     </div>

                     <div className="service-widget mb-50 border-0 p-0">
                        <div className="banner-widget">
                           <Link to="/contact">
                              <img src="img/services/services-banner.png" alt="" />
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
  )
}
export default Preventivemedis;