import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import AllContext from "./context/AllContext";
import AboutUs from "./pages/AboutUs/AboutUs";
import Appointment from "./pages/Appointment/Appointment";
import BlogLeftSideBar from "./pages/BlogLeftSideBar/BlogLeftSideBar";
import Blogs from "./pages/Blogs/Blogs";
import Contact from "./pages/Contact/Contact";
import DoctorDetails from "./pages/Doctors/DoctorDetails/DoctorDetails";
import Home from "./pages/Home/Home/Home";
import ServicesDetails from "./pages/Services/ServicesDetails/ServicesDetails";
import NotFound from "./pages/NotFound/NotFound";
import DrAkhilesh from "./pages/Doctors/DoctorDetails/Akhilesh";
import DrDeepak from "./pages/Doctors/DoctorDetails/DrDeepak";
import DrMohAhmad from "./pages/Doctors/DoctorDetails/DrMohAhmad";
import DrRakesh from "./pages/Doctors/DoctorDetails/DrRakesh";
import DrAnoop from "./pages/Doctors/DoctorDetails/Anoop";
import Gynecologist from "./pages/Departments/Gynecologist/GynecologistDetail";
import Orthopedic from "./pages/Departments/Orthopedic/OrthopedicDetail";
import Pathology from "./pages/Departments/Pathology/PathologyDetail";
import Physiotherapy from "./pages/Departments/Physiotherapy/PhysiotherapyDetail";
import Surgery from "./pages/Departments/Surgery/SurgeryDetail";
import PreventiveMediDetail from "./pages/Departments/PreventiveMedi/PreventiveMediDetail";
import Radiology from "./pages/Departments/Radiology/RadiologyDetail";
import Founder from "./pages/Founder/founderDetail";
import BookAppoinment from "./pages/Appointment/BookAppoinment/BookAppoinment";
import ContactFormArea from "./pages/Contact/ContactFormArea/ContactFormArea";
import HomeOurTeam from "./pages/Home/Home/HomeOurTeam/HomeOurTeam";
import ContactMap from "./pages/Contact/ContactMap/ContactMap";

// {/* ********* ShriDham College Import Start********** */}

import ShriDhamCollege from "./pages/ShriDhamCollege/ShriDhamCollege";
import ShriDhamCollegeHeader from "./pages/ShriDhamCollege/ShriDhamCollegeHeader";
import ShriDhamCollegeHeroSection from "./pages/ShriDhamCollege/ShriDhamCollegeHeroSection";
import ShriDhamCollegeFooter from "./pages/ShriDhamCollege/ShriDhamCollegeFooter";
import ShriDhamCollegeAbout from "./pages/ShriDhamCollege/ShriDhamCollegeAbout";
import ShriDhamCollegeFaculty from "./pages/ShriDhamCollege/ShriDhamCollegeFaculty";
import ShriDhamCourses from "./pages/ShriDhamCollege/ShriDhamCourses";
import ShriDhamCollegeHostal from "./pages/ShriDhamCollege/ShriDhamCollegeHostal";
import ShriDhamCollegeLibrary from "./pages/ShriDhamCollege/ShriDhamCollegeLibrary";
import ShriDhamSingleSlider from "./pages/ShriDhamCollege/ShriDhamSingleSlider";

function App() {
  return (
    <>
      <AllContext>
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/servicesDetails" element={<ServicesDetails />} />
            <Route path="/doctorDetails" element={<DoctorDetails />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogLeftSideBar" element={<BlogLeftSideBar />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/appoinment" element={<Appointment />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/notMatch" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/DrAkhilesh" element={<DrAkhilesh />} />
            <Route path="/DrDeepak" element={<DrDeepak />} />
            <Route path="/DrMohAhmad" element={<DrMohAhmad />} />
            <Route path="/DrRakesh" element={<DrRakesh />} />
            <Route path="/DrAnoop" element={<DrAnoop />} />
            <Route path="/Gynecologist" element={<Gynecologist />} />
            <Route path="/Orthopedic" element={<Orthopedic />} />
            <Route path="/Pathology" element={<Pathology />} />
            <Route path="/Physiotherapy" element={<Physiotherapy />} />
            <Route path="/Surgery" element={<Surgery />} />
            <Route path="/PreventiveMedi" element={<PreventiveMediDetail />} />
            <Route path="/Radiology" element={<Radiology />} />
            <Route path="/Founder" element={<Founder />} />
            <Route path="/BookAppoinment" element={<BookAppoinment />} />
            <Route path="/ContactFormArea" element={<ContactFormArea />} />
            <Route path="/HomeOurTeam" element={<HomeOurTeam />} />
            <Route path="/ContactMap" element={<ContactMap />} />

            {/* ********* ShriDham College Routing Start********** */}
            <Route path="/ShriDhamCollege" element={<ShriDhamCollege />} />
            <Route
              path="/ShriDhamCollegeHeader"
              element={<ShriDhamCollegeHeader />}
            />
            <Route
              path="/ShriDhamCollegeHeroSection"
              element={<ShriDhamCollegeHeroSection />}
            />
            <Route
              path="/ShriDhamCollegeFooter"
              element={<ShriDhamCollegeFooter />}
            />
            <Route
              path="/ShriDhamCollegeAbout"
              element={<ShriDhamCollegeAbout />}
            />
            <Route
              path="/ShriDhamCollegeFaculty"
              element={<ShriDhamCollegeFaculty />}
            />
            <Route path="/ShriDhamCourses" element={<ShriDhamCourses />} />
            <Route
              path="/ShriDhamCollegeHostal"
              element={<ShriDhamCollegeHostal />}
            />
            <Route
              path="/ShriDhamCollegeLibrary "
              element={<ShriDhamCollegeLibrary />}
            />
            <Route
              path="/ShriDhamSingleSlider "
              element={<ShriDhamSingleSlider />}
            />
          </Routes>
        </BrowserRouter>
      </AllContext>
    </>
  );
}

export default App;
