import React from 'react'
import { Link } from 'react-router-dom';
import { GiMicroscope } from "react-icons/gi";
import { GiMedicines } from "react-icons/gi";
import { FaXRay } from "react-icons/fa6";
import { TbPhysotherapist } from "react-icons/tb";
import { TbDisabled } from "react-icons/tb";
import { FaRegHospital } from 'react-icons/fa';
import care from '../care.png';
import service from '../lifesupport.png';
import Gayno from './GYNOlOGY_746x465.png';


export const Gynecologist = () => {
  return (
    <>
         <div className="service-details-area pt-120 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 col-lg-8">
                     <article className="service-details-box">
                        <div className="service-details-thumb mb-80">
                           <img className="img" src={Gayno} alt="650*406" />
                        </div>
                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">OBSTETRZCS & GYNECOLOGY CARE</h5>
                              <h1>We are here to help when you need us.</h1>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                           <p>Gynecology care is a critical component of women's healthcare, focusing on the unique and complex aspects of the female reproductive system. These specialized medical professionals, known as gynecologists, play a vital role in promoting and maintaining women's health throughout various life stages.</p>

                           <p>Gynecologists provide a spectrum of services, ranging from routine check-ups to specialized care for reproductive health issues. Annual examinations, commonly known as pelvic exams or pap smears, are integral to early detection of conditions such as cervical cancer and sexually transmitted infections. Gynecologists also offer guidance on contraception, family planning, and fertility concerns, tailoring their advice to individual needs.</p>

                           <p>Prenatal care is another essential facet of gynecological services, ensuring the well-being of both mother and child during pregnancy. Gynecologists monitor the progression of pregnancies, offer prenatal screenings, and provide valuable support and information to expectant mothers.</p>

                           <p>Beyond reproductive health, gynecologists address a variety of women's health concerns, including menstrual disorders, hormonal imbalances, and menopausal symptoms. Their expertise extends to the diagnosis and treatment of conditions such as endometriosis, uterine fibroids, and ovarian cysts.</p>

                           <p>
                           In fostering a patient-centered approach, gynecologists prioritize open communication and create a safe space for women to discuss intimate health matters. By providing comprehensive care that spans preventive measures, early detection, and treatment, gynecologists contribute significantly to the overall well-being of women, emphasizing the importance of reproductive health in the broader context of women's healthcare.
                           </p>
                           
                        </div>
                        <div className="service-details-feature fix mb-35">
                           <div className="ser-fea-box f-left">
                              <div className="ser-fea-icon f-left">
                                 <img src={care} alt="care" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Personal care</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Comprehensive Health Assessments </li>
                                    <li><i className="fas fa-check"></i>Expert Medical Consultations</li>
                                    <li><i className="fas fa-check"></i>Efficient Coordination of Care</li>
                                    <li><i className="fas fa-check"></i>Patient-Centric Approach,</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="ser-fea-box f-left me-0">
                              <div className="ser-fea-icon f-left">
                                 <img src={service} alt="" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Lifestyle support</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Personalized Nutrition Guidance,</li>
                                    <li><i className="fas fa-check"></i>Fitness and Exercise Programs,</li>
                                    <li><i className="fas fa-check"></i>Behavioral Health Support,</li>
                                    <li><i className="fas fa-check"></i>Sleep Improvement Strategies.</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                           
                        {/* <div className="testi-box text-center pos-rel mb-0">
                           <div className="testi-content testi-service-content pos-rel">
                              <div className="testi-quato-icon">
                                 <img src="img/testimonials/testi-quato-icon.png" alt="" />
                              </div>
                              <p>The happiest clients are those who take their time to really think about it. They come back again and again because their experience was positive, memorable, and rewarding.</p>
                              <span></span>
                           </div>
                           <div className="testi-author">
                              <h2 className="testi-author-title">Vinay Dhariya</h2>
                              <span className="testi-author-desination">ShriDham Hospital</span>
                           </div>
                           <div className="test-author-icon mb-10 rounded-circle">
                              <img src={ladka} alt="apna-ladka" className='rounded-circle' />
                           </div>
                        </div> */}
                     </article>
                  </div>
                  <div className="col-xl-5 col-lg-4">
                     <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">More Services</h3>
                        </div>
                        <div className="more-service-list">
                           <ul>
                             <li>
                                 <Link to="/PreventiveMedi">
                                    <div className="more-service-icon fs-1 text-danger"><GiMedicines /></div>
                                    <div className="more-service-title">General Medicine</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Orthopedic">
                                    <div className="more-service-icon fs-1 text-danger"><TbDisabled /></div>
                                    <div className="more-service-title">Orthopedic Care</div>
                                 </Link>
                              </li>
                              {/* <li>
                                 <Link to="/Gynecologist">
                                    <div className="more-service-icon fs-1 text-danger"><FaVenus /></div>
                                    <div className="more-service-title">Gynecologist Care</div>
                                 </Link>
                              </li> */}
                              <li>
                                 <Link to="/Surgery">
                                    <div className="more-service-icon fs-1 text-danger"><FaRegHospital/></div>
                                    <div className="more-service-title">General Surgery</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Radiology">
                                    <div className="more-service-icon fs-1 text-danger"><FaXRay/></div>
                                    <div className="more-service-title">RADIOLOGY</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Pathology">
                                    <div className="more-service-icon fs-1 text-danger"><GiMicroscope /></div>
                                    <div className="more-service-title"> PATHOLOGY DEPARTMENT</div>
                                 </Link>
                              </li>      
                              
                              <li>
                                 <Link to="/Physiotherapy">
                                    <div className="more-service-icon fs-1 text-danger"><TbPhysotherapist /></div>
                                    <div className="more-service-title">PHYSIOTHERAPY </div>
                                 </Link>
                              </li>
                              
                              
                           </ul>
                        </div>
                     </div>
                     
                     <div className="service-widget mb-50 border-0 p-0">
                        <div className="banner-widget">
                           <Link to="/contact">
                              <img src="img/services/services-banner.png" alt="" />
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
  )
}
export default Gynecologist;