import React from 'react'
import { Link } from 'react-router-dom';
import { GiMicroscope } from "react-icons/gi";
import { GiMedicines } from "react-icons/gi";
import { FaXRay } from "react-icons/fa6";
import { TbPhysotherapist } from "react-icons/tb";
import { TbDisabled } from "react-icons/tb";
import { FaRegHospital } from 'react-icons/fa';
import {FaVenus} from 'react-icons/fa';
import care from '../care.png';
import service from '../lifesupport.png';
import pathology from './pathologi_746x465.webp'

export const Pathology = () => {
  return (
    <>
         <div className="service-details-area pt-120 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 col-lg-8">
                     <article className="service-details-box">
                        <div className="service-details-thumb mb-80">
                           <img className="img" src={pathology} alt="650*406" />
                        </div>
                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">PATHOLOGY DEPARTMENT</h5>
                              <h1>We are here to help when you need us.</h1>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">

                           <p>"The Pathology Department serves as a critical hub in healthcare, playing a pivotal role in the accurate diagnosis and understanding of diseases. Comprising skilled pathologists, laboratory technicians, and support staff, this department employs a range of sophisticated techniques to analyze tissues, cells, and bodily fluids, providing invaluable insights that guide patient care.</p>

                           <p>Pathologists within the department are highly trained in interpreting laboratory results and understanding the underlying mechanisms of diseases. From examining tissue samples under a microscope to employing advanced molecular diagnostics, the Pathology Department contributes crucial information that aids clinicians in formulating precise treatment plans.</p>

                           <p>The laboratory technicians in this department operate cutting-edge equipment to perform a variety of tests, including blood analyses, genetic testing, and microbiological cultures. Their meticulous work ensures the accuracy and reliability of diagnostic results, supporting the broader medical team in delivering personalized and effective patient care.</p>

                           <p>In addition to diagnosing diseases, the Pathology Department plays a vital role in cancer staging, contributing essential information for oncologists to determine the appropriate course of treatment. The department's expertise extends to forensic pathology, supporting legal investigations by providing insights into the cause of death and other relevant factors.</p>

                           <p>Continuous research and innovation are fundamental to the Pathology Department, ensuring it stays at the forefront of diagnostic capabilities. The department is committed to upholding the highest standards of quality, precision, and ethical practices, making it an indispensable component of the healthcare landscape. Whether through routine screenings or complex diagnostic procedures, the Pathology Department is dedicated to advancing medical knowledge and improving patient outcomes."</p>

                        </div>
                        <div className="service-details-feature fix mb-35">
                           <div className="ser-fea-box f-left">
                              <div className="ser-fea-icon f-left">
                                 <img src={care} alt="care" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Personal care</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Comprehensive Health Assessments </li>
                                    <li><i className="fas fa-check"></i>Expert Medical Consultations</li>
                                    <li><i className="fas fa-check"></i>Efficient Coordination of Care</li>
                                    <li><i className="fas fa-check"></i>Patient-Centric Approach,</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="ser-fea-box f-left me-0">
                              <div className="ser-fea-icon f-left">
                                 <img src={service} alt="" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Lifestyle support</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Personalized Nutrition Guidance,</li>
                                    <li><i className="fas fa-check"></i>Fitness and Exercise Programs,</li>
                                    <li><i className="fas fa-check"></i>Behavioral Health Support,</li>
                                    <li><i className="fas fa-check"></i>Sleep Improvement Strategies.</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                           
                        {/* <div className="testi-box text-center pos-rel mb-0">
                           <div className="testi-content testi-service-content pos-rel">
                              <div className="testi-quato-icon">
                                 <img src="img/testimonials/testi-quato-icon.png" alt="" />
                              </div>
                              <p>The happiest clients are those who take their time to really think about it. They come back again and again because their experience was positive, memorable, and rewarding.</p>
                              <span></span>
                           </div>
                           <div className="testi-author">
                              <h2 className="testi-author-title">Vinay Dhariya</h2>
                              <span className="testi-author-desination">ShriDham Hospital</span>
                           </div>
                           <div className="test-author-icon mb-10 rounded-circle">
                              <img src={ladka} alt="apna-ladka" className='rounded-circle' />
                           </div>
                        </div> */}
                     </article>
                  </div>
                  <div className="col-xl-5 col-lg-4">
                     <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">More Services</h3>
                        </div>
                        <div className="more-service-list">
                           <ul>
                             <li>
                                 <Link to="/PreventiveMedi">
                                    <div className="more-service-icon fs-1 text-danger"><GiMedicines /></div>
                                    <div className="more-service-title">General Medicine</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Orthopedic">
                                    <div className="more-service-icon fs-1 text-danger"><TbDisabled /></div>
                                    <div className="more-service-title">Orthopedic Care</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Gynecologist">
                                    <div className="more-service-icon fs-1 text-danger"><FaVenus /></div>
                                    <div className="more-service-title">Gynecology Care</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Surgery">
                                    <div className="more-service-icon fs-1 text-danger"><FaRegHospital/></div>
                                    <div className="more-service-title">General Surgery</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Radiology">
                                    <div className="more-service-icon fs-1 text-danger"><FaXRay/></div>
                                    <div className="more-service-title">RADIOLOGY</div>
                                 </Link>
                              </li>
                              {/* <li>
                                 <Link to="/Pathology">
                                    <div className="more-service-icon fs-1 text-danger"><GiMicroscope /></div>
                                    <div className="more-service-title"> PATHOLOGY DEPARTMENT</div>
                                 </Link>
                              </li>       */}
                              
                              <li>
                                 <Link to="/Physiotherapy">
                                    <div className="more-service-icon fs-1 text-danger"><TbPhysotherapist /></div>
                                    <div className="more-service-title">PHYSIOTHERAPY </div>
                                 </Link>
                              </li>
                              
                              
                           </ul>
                        </div>
                     </div>
                     {/* <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">Get Some Advice?</h3>
                        </div>
                        <form className="service-contact-form" action="">
                           <div className="row">
                              <div className="col-xl-12">
                                 <div className="contact-input contact-icon contact-user mb-20">
                                    <input type="text" placeholder="Enter your name" />
                                 </div>
                              </div>
                              <div className="col-xl-12">
                                 <div className="contact-input contact-icon contact-mail mb-20">
                                    <input type="text" placeholder="Enter your email" />
                                 </div>
                              </div>
                              <div className="col-xl-12">
                                 <div className="contact-input contact-icon contact-hourglass">

                                    <select className="form-select select_style" aria-label="Default select example">
                                       <option defaultValue="Select type of care">Select type of care</option>
                                       <option defaultValue="1">Select type of care</option>
                                       <option defaultValue="2">Select type of care</option>
                                       <option defaultValue="2">Select type of care</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                        </form>
                        <div className="ser-form-btn text-center mt-40">
                           <a href="#" className="primary_btn btn-icon ml-0"
                              style={{ animationDelay: '0.6s' }}
                              tabIndex="0"><span>+</span>Request for call</a>
                        </div>
                     </div> */}
                     <div className="service-widget mb-50 border-0 p-0">
                        <div className="banner-widget">
                           <Link to="/contact">
                              <img src="img/services/services-banner.png" alt="" />
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
  )
}
export default Pathology;