import React from "react";
import FrontGate from "./front500+700.jpg";
import Founder from "./Picsart_23-06-30_16-44-17-698_80x80.jpg";

const HomeAboutArea = () => {
  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 about_left">
              <div className="medical-icon-brand-2">
                <img src="img/about/medical-brand-icon-border.png" alt="" />
              </div>
              <div className="about-left-side pos-rel mt-lg-4">
                <div className="about-front-img">
                  <img src={FrontGate} alt="" className="rounded-3" />
                </div>
                <div className="about-shape">
                  <img src="img/about/about-shape.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="about-right-side pt-55 mb-30">
                <div className="about-title mb-20">
                  <h5>About Us</h5>
                  <h1>Short Story About Shridham Hospital.</h1>
                </div>
                <div className="about-text">
                  <p>
                    Welcome to Shridham Hospital, where we are committed to
                    redefining healthcare excellence through a patient-centric
                    approach, cutting-edge medical technology, and a legacy of
                    compassionate care. As a leading healthcare institution, we
                    take pride in our rich history, unwavering dedication to
                    quality, and our relentless pursuit of innovation. This
                    comprehensive narrative will guide you through the essence
                    of Shridham Hospital, encapsulating our journey, mission,
                    values, and the profound impact we aim to make in the lives
                    of those we serve.
                  </p>
                  <h3>Our Legacy and Journey:</h3>
                  <p>
                    Established in [2020], Shridham Hospital emerged as a
                    visionary endeavor to create a healthcare destination that
                    transcends conventional standards. Over the years, our
                    journey has been marked by milestones that reflect our
                    commitment to providing comprehensive, state-of-the-art
                    healthcare services. From our humble beginnings, we have
                    grown into a renowned institution, recognized for our
                    excellence in medical care, research, and community
                    outreach.
                  </p>{" "}
                  <br />
                </div>
                <div className="about-author d-flex align-items-center rounded-circle">
                  <div className="author-ava ">
                    <img src={Founder} alt="" className="rounded-circle" />
                  </div>
                  <div className="author-desination">
                    <h4>Rohit Bilwar</h4>
                    <h6>Founder</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAboutArea;
