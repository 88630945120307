import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import DrDeepakDetails from './Dr.Deepak Mishra/DrDeepakDetails';
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';

export const DrDeepak = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title="Dr.Deepak Mishra" subtitle="Details" />
    <DrDeepakDetails />
    <HomeFooter/>
</>
  )
}
export default DrDeepak;