import React from 'react'
import { Link } from 'react-router-dom';
import { GiMicroscope } from "react-icons/gi";
import { GiMedicines } from "react-icons/gi";
import { FaXRay } from "react-icons/fa6";
import { TbPhysotherapist } from "react-icons/tb";
import { TbDisabled } from "react-icons/tb";
import { FaRegHospital } from 'react-icons/fa';
import {FaVenus} from 'react-icons/fa';
import care from '../care.png';
import service from '../lifesupport.png';
import Physio from './physiotherapy-746x465.webp'

export const physiotherapy = () => {
  return (
    <>
         <div className="service-details-area pt-120 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 col-lg-8">
                     <article className="service-details-box">
                        <div className="service-details-thumb mb-80">
                           <img className="img" src={Physio} alt="650*406" />
                        </div>
                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">PHYSIOTHERAPY</h5>
                              <h1>We are here to help when you need us.</h1>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">

                           <p>"The Physiotherapy Department is an integral component of healthcare, focusing on restoring and optimizing physical function to enhance the overall well-being of individuals. Comprising skilled physiotherapists, rehabilitation specialists, and support staff, this department plays a key role in promoting mobility, reducing pain, and improving the quality of life for patients.</p>

                           <p>Physiotherapists within the department are highly trained professionals who assess, diagnose, and treat a variety of musculoskeletal, neurological, and respiratory conditions. They employ evidence-based techniques, therapeutic exercises, and manual therapies to address impairments and disabilities, tailoring treatment plans to the unique needs of each patient.</p>

                           <p>The department collaborates closely with other healthcare professionals to provide comprehensive care. Whether it's aiding in the recovery from surgery, managing chronic conditions, or assisting in rehabilitation after injury, physiotherapists contribute significantly to the holistic approach to patient health.</p>

                           <p>Education is a fundamental aspect of the Physiotherapy Department's mission, empowering patients with the knowledge and tools they need to actively participate in their own recovery. This patient-centered approach fosters a sense of partnership between the physiotherapist and the individual undergoing rehabilitation.</p>

                           <p>The Physiotherapy Department also extends its services to preventive care, offering advice on posture, ergonomics, and lifestyle modifications to reduce the risk of injuries and promote long-term physical health. Through a combination of expertise, empathy, and dedication, the Physiotherapy Department plays a crucial role in restoring function, independence, and improving the overall quality of life for those it serves."</p>

                        </div>
                        <div className="service-details-feature fix mb-35">
                           <div className="ser-fea-box f-left">
                              <div className="ser-fea-icon f-left">
                                 <img src={care} alt="care" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Personal care</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Comprehensive Health Assessments </li>
                                    <li><i className="fas fa-check"></i>Expert Medical Consultations</li>
                                    <li><i className="fas fa-check"></i>Efficient Coordination of Care</li>
                                    <li><i className="fas fa-check"></i>Patient-Centric Approach,</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="ser-fea-box f-left me-0">
                              <div className="ser-fea-icon f-left">
                                 <img src={service} alt="" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Lifestyle support</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Personalized Nutrition Guidance,</li>
                                    <li><i className="fas fa-check"></i>Fitness and Exercise Programs,</li>
                                    <li><i className="fas fa-check"></i>Behavioral Health Support,</li>
                                    <li><i className="fas fa-check"></i>Sleep Improvement Strategies.</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                           
                     </article>
                  </div>
                  <div className="col-xl-5 col-lg-4">
                     <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">More Services</h3>
                        </div>
                        <div className="more-service-list">
                           <ul>
                             <li>
                                 <Link to="/PreventiveMedi">
                                    <div className="more-service-icon fs-1 text-danger"><GiMedicines /></div>
                                    <div className="more-service-title">General Medicine</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Orthopedic">
                                    <div className="more-service-icon fs-1 text-danger"><TbDisabled /></div>
                                    <div className="more-service-title">Orthopedic Care</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Gynecologist">
                                    <div className="more-service-icon fs-1 text-danger"><FaVenus /></div>
                                    <div className="more-service-title">Gynecology Care</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Surgery">
                                    <div className="more-service-icon fs-1 text-danger"><FaRegHospital/></div>
                                    <div className="more-service-title">General Surgery</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Radiology">
                                    <div className="more-service-icon fs-1 text-danger"><FaXRay/></div>
                                    <div className="more-service-title">RADIOLOGY</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Pathology">
                                    <div className="more-service-icon fs-1 text-danger"><GiMicroscope /></div>
                                    <div className="more-service-title"> PATHOLOGY DEPARTMENT</div>
                                 </Link>
                              </li>      
                              
                              {/* <li>
                                 <Link to="/Physiotherapy">
                                    <div className="more-service-icon fs-1 text-danger"><TbPhysotherapist /></div>
                                    <div className="more-service-title">PHYSIOTHERAPY </div>
                                 </Link>
                              </li> */}
                              
                              
                           </ul>
                        </div>
                     </div>
                      {/* <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">Get Some Advice?</h3>
                        </div>
                        <form className="service-contact-form" action="">
                           <div className="row">
                              <div className="col-xl-12">
                                 <div className="contact-input contact-icon contact-user mb-20">
                                    <input type="text" placeholder="Enter your name" />
                                 </div>
                              </div>
                              <div className="col-xl-12">
                                 <div className="contact-input contact-icon contact-mail mb-20">
                                    <input type="text" placeholder="Enter your email" />
                                 </div>
                              </div>
                              <div className="col-xl-12">
                                 <div className="contact-input contact-icon contact-hourglass">

                                    <select className="form-select select_style" aria-label="Default select example">
                                       <option defaultValue="Select type of care">Select type of care</option>
                                       <option defaultValue="1">Select type of care</option>
                                       <option defaultValue="2">Select type of care</option>
                                       <option defaultValue="2">Select type of care</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                        </form>
                        <div className="ser-form-btn text-center mt-40">
                           <a href="#" className="primary_btn btn-icon ml-0"
                              style={{ animationDelay: '0.6s' }}
                              tabIndex="0"><span>+</span>Request for call</a>
                        </div>
                     </div> */}
                     <div className="service-widget mb-50 border-0 p-0">
                        <div className="banner-widget">
                           <Link to="/contact">
                              <img src="img/services/services-banner.png" alt="" />
                           </Link>
                        </div>
                     </div> 
                  </div>
               </div>
            </div>
         </div>
      </>
  )
}
export default physiotherapy;