import React, { useState } from 'react'
import axios from "axios";
import cogoToast from 'cogo-toast';

export const ContactUs = () => {
   const [data, setData] = useState({
      name:"",
      mobile: "",
      email:"",
      message:""
   });

   const handleInputChange = (event) => {
      const { name, value, type, checked } = event.target;
      setData({
        ...data,
        [name]: type === "checkbox" ? checked : value,
      });
    };

  const handleSubmit = async(e)=>{
   e.preventDefault();
   try {
      const response = await axios.post("https://shridhamhospitalgotegaon.com/api/auth/enquirymail", data);
      console.log(response.data);
      cogoToast.success('messsage has been sent successfully');
      alert("messsage has been sent successfully");
      setData({
         name:"",
         mobile: "",
         email:"",
         message:""
      });
   } catch (error) {
      console.log(error);
   }
  }

  console.log(data);
  return (
    <>
    <section className="appointment-area appointment-area-3 appointment_page_bg pos-rel pt-115 pb-120"
       data-background="img/appoinment/appointment-bg.jpg">
       <div className="container">
         <form onSubmit={handleSubmit}>
         <div className="row">
             <div className="col-xl-7 col-lg-8">
                <div className="calculate-box white-bg">
                   <div className="calculate-content">
                      <div className="row">
                         <div className="col-xl-12">
                            <div className="about-title news-letter-title mb-70">
                               <h5 className="pink-color">Appointment</h5>
                               <h1>Contact Us</h1>
                            </div>
                         </div>
                      </div>
                      <div className="row">
                         

                         <div className="col-xl-12 col-lg-12 col-md-6">
                         <div className="calculate-form appointment-form-3 mb-20" action="#">
                               <input type="text" placeholder="Your Full Name" name='name' value={data.name} onChange={handleInputChange}  required/>
                            </div>
                         </div>

                         <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="calculate-form appointment-form-3 mb-20" action="#">
                               <input type="text" placeholder="Your Phone number" name='mobile' value={data.mobile} onChange={handleInputChange}  required/>
                            </div>
                         </div>


                         <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="calculate-form appointment-form-3 mb-20" action="#">
                              <input type="email" placeholder="Your Email" name='email' value={data.email} onChange={handleInputChange}  required/>
                            </div>
                         </div>

                         <div className="col-xl-12">
                            <div className="calculate-form appointment-form-3 mb-20" action="#">
                               <textarea name="message" cols="30" rows="10" placeholder="message" value={data.message}  onChange={handleInputChange}  required></textarea>
                            </div>
                         </div>
                      </div>
                   </div>
                   <div className='d-flex justify-content-center'>
                   <button type='submit' className="primary_btn mb-4 rounded">submit</button>
                   </div>
                 
                </div>
             </div>
          </div>
         </form>
         
       </div>
    </section>
 </>

  )
}

export default ContactUs;