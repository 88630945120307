import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
 import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import HomeOurTeam from '../Home/Home/HomeOurTeam/HomeOurTeam';
import AboutArea from './AboutArea/AboutArea';
import AboutTestimonial from './AboutTestimonial/AboutTestimonial';
import HomeFooter from '../Home/Home/HomeFooter/HomeFooter';

const AboutUs = () => {
   return (
      <>
          <HomeHeader/> 
         <CommonPageHeader title="About Us" subtitle="About" />
         <AboutArea/>
         <HomeOurTeam/>
         <AboutTestimonial/>
         <HomeFooter/>
      </>
   );
};

export default AboutUs;