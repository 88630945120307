import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';
import Preventivemedis from './Preventivemedis';

export const PreventiveMediDetail = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title="GENERAL MEDICINE" subtitle="Details" />
    <Preventivemedis/>
    <HomeFooter/>
</>
  )
}
export default PreventiveMediDetail;