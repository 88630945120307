import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import DoctorDetailsArea from './DoctorDetailsArea/DoctorDetailsArea';
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';

const DoctorDetails = () => {
    return (
        <>
            <HomeHeader/>
            <CommonPageHeader title="Dr.A verma" subtitle="Details" />
            <DoctorDetailsArea/>
            <HomeFooter/>
        </>
    );
};

export default DoctorDetails;