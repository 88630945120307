import React from "react";

const ShriDhamCollegeFaculty = () => {
  return (
    <>
      <section className=" gray-bg mt-5">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <h2 className="d-flex justify-content-center ms-2 mt-5">Lab</h2>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div data-aos="flip-down" data-aos-duration="2000">
                <div class="card py-3 rounded-pill bg-warning mt-md-4 mt-4">
                  <h4 class="card-text text-center">
                    Pre Clinical Science Lab
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div data-aos="flip-down" data-aos-duration="2000">
                <div class="card py-3 rounded-pill bg-warning mt-md-4 mt-4">
                  <h4 class="card-text text-center">Nursing Foundation Lab</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div data-aos="flip-down" data-aos-duration="2000">
                <div class="card py-3 rounded-pill bg-warning mt-md-4 mt-4">
                  <h4 class="card-text text-center">Computer Lab</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div data-aos="flip-down" data-aos-duration="2000">
                <div class="card py-3 rounded-pill bg-warning mt-md-4 mt-4 mb-lg-4">
                  <h4 class="card-text text-center">Nutrition Lab</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div data-aos="flip-up" data-aos-duration="2000">
                <div class="card py-3 rounded-pill bg-warning mt-md-4 mb-md-4 mt-4">
                  <h4 class="card-text text-center">
                    OBG Lab / Poediatric Lab
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div data-aos="flip-down" data-aos-duration="2000">
                <div class="card py-3 rounded-pill bg-warning mt-md-4 mb-md-4 mt-4">
                  <h4 class="card-text text-center">
                    Advance Nursing skill Lab
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShriDhamCollegeFaculty;
