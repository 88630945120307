import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Collapsible from "react-collapsible";
// import { FaFacebookSquare } from "react-icons/fa";
// import { FaInstagramSquare } from "react-icons/fa";
// import { BsYoutube } from "react-icons/bs";
// import { BsLinkedin } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";

const Sidebar = ({ show, handleClose }) => {
  const Home = <NavLink to="/">Home</NavLink>;
  const AboutUs = <NavLink to="/about">About Us </NavLink>;
  const Services = <NavLink to="/servicesDetails">Services </NavLink>;
  const Departments = (
    <NavLink to="#">
      Departments <RiArrowDropDownLine className="fs-3" />{" "}
    </NavLink>
  );
  const Doctors = (
    <NavLink to="#">
      Doctors <RiArrowDropDownLine className="fs-3" />{" "}
    </NavLink>
  );
  const OurCollege = <NavLink to="/ShriDhamCollege">Our College</NavLink>;
  // const Blogs = <NavLink to="/blogSidebar">Blogs</NavLink>
  const Contact = <NavLink to="/contact">Contact Us</NavLink>;
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Collapsible
              trigger={Home}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={AboutUs}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={Services}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            ></Collapsible>

            <Collapsible
              trigger={Departments}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="/PreventiveMedi">PREVENTIVE MEDICINE</NavLink>
                </li>
                <li>
                  <NavLink to="/Orthopedic">ORTHOPEDIC CARE</NavLink>
                </li>
                <li>
                  <NavLink to="/Gynecologist">GYNECOLOGIST CARE</NavLink>
                </li>
                <li>
                  <NavLink to="/Surgery">GENERAL SURGERY</NavLink>
                </li>
                <li>
                  <NavLink to="/Radiology">RADIOLOGY</NavLink>
                </li>
                <li>
                  <NavLink to="/Pathology">PATHOLOGY DEPARTMENT</NavLink>
                </li>
                <li>
                  <NavLink to="/Physiotherapy">PHYSIOTHERAPY</NavLink>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger={Doctors}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="/doctorDetails">Dr.A Verma</NavLink>
                </li>
                <li>
                  <NavLink to="/DrAkhilesh">Dr.Akhilesh Tiwari</NavLink>
                </li>
                <li>
                  <NavLink to="/DrDeepak">Dr.Deepak Mishra</NavLink>
                </li>
                <li>
                  <NavLink to="/DrMohAhmad">Dr.Moh.Ahmad Khan</NavLink>
                </li>
                <li>
                  <NavLink to="/DrRakesh">Dr.Rakesh Kumar</NavLink>
                </li>
                <li>
                  <NavLink to="/DrAnoop">Dr.Shailja Gupta</NavLink>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger={OurCollege}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            ></Collapsible>

            {/* <Collapsible trigger={Blogs} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                      <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/blogs">Blog+</NavLink></li> 
                     </ul>
                  </Collapsible>  */}

            <Collapsible
              trigger={Contact}
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            ></Collapsible>
          </Offcanvas.Body>
        </Offcanvas>

        {/* <div className="header-social-icons fs-6">
                    <ul>
                      <li>
                        <Link
                          to="https://www.facebook.com/shridhammultispecialityhospital/"
                          target="_blank">
                          <FaFacebookSquare />
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="https://www.instagram.com/hospitalshridham/"
                          target="_blank">
                          <FaInstagramSquare />
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="https://youtube.com/@ShridhamHospital?si=-LJGnKsfzwHJsNpl"
                          target="_blank">
                          <BsYoutube />
                        </Link>
                      </li>
                    </ul>
                  </div> */}
      </div>
    </>
  );
};

export default Sidebar;
