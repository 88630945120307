import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import Radiology from '../Radiology/Radiology'
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';

export const RadiologyDetail = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title="RADIOLOGY" subtitle="Details" />
    <Radiology/>
    <HomeFooter/>
</>
  )
}
export default RadiologyDetail;