import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import ContactArea from './ContactArea/ContactArea';
// import ContactFormArea from './ContactFormArea/ContactFormArea';
import ContactMap from './ContactMap/ContactMap';
import HomeFooter from '../Home/Home/HomeFooter/HomeFooter';
// import BookAppoinment from '../Appointment/BookAppoinment/BookAppoinment';
import ContactUs from './ContactUs';

const Contact = () => {
    return (
        <>
            <HomeHeader/>
            <CommonPageHeader title="Contact Us" subtitle="Contact" />
            <ContactArea/>
            <ContactUs />
            {/* <ContactFormArea/> */}
            <ContactMap/>
            <HomeFooter/>
        </>
    );
};

export default Contact;