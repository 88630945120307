import axios from "axios";
import cogoToast from "cogo-toast";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const ContactFormArea = () => {
  const [data, setData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setData({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://shridhamhospitalgotegaon.com/api/auth/enquirymail",
        data
      );
      console.log(response.data);
      cogoToast.success("messsage has been sent successfully");
      alert("messsage has been sent successfully");
      setData({
        name: "",
        mobile: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(data);
  return (
    <>
      <section className="contact-form-area gray-bg pt-100 pb-100">
        <div className="container">
          <div className="form-wrapper">
            <div className="row align-items-center">
              <div className="col-xl-8 col-lg-8">
                <div className="section-title mb-55">
                  <p>
                    <span></span> Anything On your Mind
                  </p>
                  <h1>Estimate Your Idea</h1>
                </div>
              </div>
              <div className="col-xl-4 col-lg-3 d-none d-xl-block ">
                <div className="section-link mb-80 text-end">
                  <Link
                    to="/BookAppoinment"
                    className="primary_btn btn-icon ml-0"
                  >
                    <span>+</span>Make Appointment
                  </Link>
                </div>
              </div>
            </div>
            <div className="contact-form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-box user-icon mb-30">
                      <input
                        type="text"
                        placeholder="Your Full Name"
                        name="name"
                        value={data.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-box email-icon mb-30">
                      <input
                        type="text"
                        placeholder="Your Phone number"
                        name="mobile"
                        value={data.mobile}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-box subject-icon mb-30">
                      <input
                        type="text"
                        placeholder="Your Email"
                        name="email"
                        value={data.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-box message-icon mb-30">
                      <textarea
                        name="message"
                        cols="30"
                        rows="10"
                        placeholder="message"
                        value={data.message}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                    <div className="contact-btn text-center">
                      <button
                        className="primary_btn btn-icon ml-0"
                        type="submit"
                      >
                        <span>+</span> get action
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <p className="ajax-response text-center"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactFormArea;
