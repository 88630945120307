import React from "react";
import HomeSIngleService from "../../../../components/HomeSIngleService/HomeSIngleService";

const HomeServices = () => {
  return (
    <>
      <section className="servcies-area gray-bg pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                <div className="section-icon">
                  <img
                    className="section-back-icon"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text pos-rel home_ser_title">
                  <h5>Departments</h5>
                  <h1>Managed Your Health Care Services</h1>
                </div>
                <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <HomeSIngleService
              icon="2"
              title="General Medicine"
              paragraph="In this busy life, paying attention to health is imperative. Sadly, most of us overlook it the most. So, if you have to remain fit and healthy, the best that you can do is to get hold of medical &  medicine"
              link="/PreventiveMedi"
            />

            <HomeSIngleService
              icon="5"
              title="Orthopedic Care"
              paragraph="Shridham Hospital has Orthopedicians who were trained at the top centres worldwide, who are updated with the latest technologies and equipments andadvanced physical therapy facilities. here nonoperative treatments."
              link="/Orthopedic"
            />

            <HomeSIngleService
              icon="6"
              title="Gynecology Care"
              paragraph="Gynecology care centers on the health of individuals assigned female at birth. These medical doctors specialize in the female reproductive system, delivering comprehensive care across different life stages."
              link="/Gynecologist"
            />

            <HomeSIngleService
              icon="1"
              title="General Surgery"
              paragraph=" General surgery is a medical specialty that involves the surgical treatment of a wide range of diseases and conditions. General surgeons are trained to operate on various body systems, providing both elective and emergency surgical."
              link="/Surgery"
            />

            <HomeSIngleService
              icon="3"
              title="Radiology"
              paragraph="Radiology is the medical specialty utilizing imaging for disease diagnosis and treatment. Radiologists, specialized physicians, interpret images and employ diverse technologies for minimally invasive procedures."
              link="/Radiology"
            />

            <HomeSIngleService
              icon="4"
              title="Physiotherapy"
              paragraph="Physiotherapists or physical therapists use a variety of techniques and exercises to address a wide range of condition, injurie.Physiotherapy profession focused on maintaining physical function and mobility."
              link="/Physiotherapy"
            />
            <div className="d-flex justify-content-center">
              <HomeSIngleService
                icon="7"
                title="Pathology Department"
                paragraph="
              In the busy pace of life, health often takes a backseat. In pathology, prioritize well-being by accessing essential services and prescribed medicine for a healthier life."
                link="/Pathology"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeServices;
