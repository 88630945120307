import React from 'react';
import { Link } from 'react-router-dom';
import HomeSingleTeam from '../../../../components/HomeSingleTeam/HomeSingleTeam';

const HomeOurTeam = () => {
   return (
      <>
         <section className="team-area pt-115 pb-55">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-10">
                     <div className="section-title pos-rel mb-75">
                        <div className="section-icon">
                           <img className="section-back-icon back-icon-left" src="img/section/section-back-icon.png" alt=""/>
                        </div>
                        <div className="section-text pos-rel">
                           <h5>Our Team</h5>
                           <h1>A Professional & Care Provider</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-5">
                     <div className="section-button text-end d-none d-lg-block pt-80 team_btn">
                        <Link to="/BookAppoinment" className="primary_btn btn-icon ml-0"><span>+</span>Make Appointment</Link>
                     </div>
                  </div>
               </div>
               <div className="row">

                  
                  
                  <HomeSingleTeam image="2" title="Dr.Akhilesh Tiwari" subtitle="CCEDM(DIABETES) CCTD (THYROID)" link="/DrAkhilesh"/>                 
                  <HomeSingleTeam image="3" title="Dr.Deepak Mishra" subtitle="DNB FIJRS FICTS " link="/DrDeepak"/>                 
                  <HomeSingleTeam image="4" title="Dr.Moh.Ahmad Khan" subtitle="ENT Audiologist Physician" link="/DrMohAhmad"/>
                  <HomeSingleTeam image="5" title="Dr.Rakesh Kumar" subtitle="Radiology" link="/DrRakesh" />
                  <HomeSingleTeam image="6" title="Dr.Shailja Gupta" subtitle="Gynecologist & Surgeon" link="/DrAnoop"/>
                  <HomeSingleTeam image="1" title="Dr.A Verma" subtitle="MBBS DNB(Gen Surgery) FMAS" link="/doctorDetails" />
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeOurTeam;