import React from 'react'
import { Link } from 'react-router-dom';
import { GiMicroscope } from "react-icons/gi";
import { GiMedicines } from "react-icons/gi";
import { FaXRay } from "react-icons/fa6";
import { TbPhysotherapist } from "react-icons/tb";
import { FaRegHospital } from 'react-icons/fa';
import {FaVenus} from 'react-icons/fa';
import { TbDisabled } from "react-icons/tb";
import Happy from'../../../Departments/orthopedics-1000x1000.webp'
import care from '../../../Departments/care.png';
import service from '../../../Departments/lifesupport.png';

const ServicesDetailsArea = () => {
  return (
    <>
    <div className="service-details-area pt-120 pb-100">
       <div className="container">
          <div className="row">
             <div className="col-xl-7 col-lg-8">
                <article className="service-details-box">
                   <div className="service-details-thumb mb-80">
                      <img className="img" src={Happy} alt="650*406" />
                   </div>
                   <div className="section-title pos-rel mb-45">
                      <div className="section-icon">
                         <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                      </div>
                      <div className="section-text pos-rel">
                         <h5 className="green-color text-up-case">OUR SERVICES</h5>
                         <h1>We are here to help when you need us.</h1>
                      </div>
                      <div className="section-line pos-rel">
                         <img src="img/shape/section-title-line.png" alt="" />
                      </div>
                   </div>
                   <div className="service-details-text mb-30">
                      <p>Welcome to our multispecialty hospital services, where patient care is our top priority At Shridham Hospital, we are dedicated to providing exceptional healthcare tailored to meet the unique needs of each individual. Our commitment to excellence is reflected in the wide range of services we offer to ensure the well-being of our patients.</p>

                      <p>Our medical expertise covers a spectrum of specialties, including but not limited to PREVENTIVE MEDICINE, ORTHOPEDICS, GYNECOLOGIST CARE, GENERAL SURGERY, RADIOLOGY, PATHOLOGY and PHYSIOTHERAPY. With state-of-the-art facilities and a team of highly skilled healthcare professionals, we strive to deliver the highest quality care.</p>

                      <p>In our pursuit of holistic healthcare, we emphasize preventive measures, early detection, and personalized treatment plans. Our diagnostic services employ cutting-edge technology, ensuring accurate and timely results. From routine check-ups to advanced medical procedures, we are equipped to handle all aspects of healthcare under one roof.</p>

                      <p>At Shridham Hospital, patient comfort and satisfaction are paramount. Our compassionate staff is dedicated to creating a healing environment that promotes well-being. We understand the importance of effective communication and collaboration between healthcare providers and patients, fostering a strong doctor-patient relationship.</p>

                      <p>In addition to clinical excellence, we prioritize community health education initiatives to empower individuals with knowledge about preventive care and healthy living. We believe in not just treating illnesses but also in promoting a culture of wellness.</p>

                      <p>Experience the difference at Shridham Hospital, where your health is our priority. Trust us to provide you and your loved ones with the highest standard of care, combining medical expertise with compassion for a healthier community.</p>
                   </div>
                   <div className="service-details-feature fix mb-35">
                      <div className="ser-fea-box f-left">
                         <div className="ser-fea-icon f-left">
                            <img src={care} alt="care" />
                         </div>
                         <div className="ser-fea-list fix">
                            <h3>Personal care</h3>
                            <ul>
                               <li><i className="fas fa-check"></i>Comprehensive Health Assessments </li>
                               <li><i className="fas fa-check"></i>Expert Medical Consultations</li>
                               <li><i className="fas fa-check"></i>Efficient Coordination of Care</li>
                               <li><i className="fas fa-check"></i>Patient-Centric Approach,</li>
                            </ul>
                         </div>
                      </div>
                      <div className="ser-fea-box f-left me-0">
                         <div className="ser-fea-icon f-left">
                            <img src={service} alt="" />
                         </div>
                         <div className="ser-fea-list fix">
                            <h3>Lifestyle support</h3>
                            <ul>
                               <li><i className="fas fa-check"></i>Personalized Nutrition Guidance,</li>
                               <li><i className="fas fa-check"></i>Fitness and Exercise Programs,</li>
                               <li><i className="fas fa-check"></i>Behavioral Health Support,</li>
                               <li><i className="fas fa-check"></i>Sleep Improvement Strategies.</li>
                            </ul>
                         </div>
                      </div>
                   </div>
                      
                </article>
             </div>
             <div className="col-xl-5 col-lg-4">
                <div className="service-widget mb-50">
                   <div className="widget-title-box mb-30">
                      <h3 className="widget-title">More Services</h3>
                   </div>
                   <div className="more-service-list">
                      <ul>
                        <li>
                            <Link to="/PreventiveMedi">
                               <div className="more-service-icon fs-1 text-danger"><GiMedicines /></div>
                               <div className="more-service-title">General Medicine</div>
                            </Link>
                         </li>

                         <li>
                            <Link to="/Orthopedic">
                               <div className="more-service-icon fs-1 text-danger"><TbDisabled /></div>
                               <div className="more-service-title">Orthopedic Care</div>
                            </Link>
                         </li>
                         
                         <li>
                            <Link to="/Gynecologist">
                               <div className="more-service-icon fs-1 text-danger"><FaVenus /></div>
                               <div className="more-service-title">Gynecology Care</div>
                            </Link>
                         </li>
                         <li>
                            <Link to="/Surgery">
                               <div className="more-service-icon fs-1 text-danger"><FaRegHospital/></div>
                               <div className="more-service-title">General Surgery</div>
                            </Link>
                         </li>
                         <li>
                            <Link to="/Radiology">
                               <div className="more-service-icon fs-1 text-danger"><FaXRay/></div>
                               <div className="more-service-title">RADIOLOGY</div>
                            </Link>
                         </li>
                         <li>
                            <Link to="/Pathology">
                               <div className="more-service-icon fs-1 text-danger"><GiMicroscope /></div>
                               <div className="more-service-title"> PATHOLOGY DEPARTMENT</div>
                            </Link>
                         </li>      
                         
                         <li>
                            <Link to="/Physiotherapy">
                               <div className="more-service-icon fs-1 text-danger"><TbPhysotherapist /></div>
                               <div className="more-service-title">PHYSIOTHERAPY </div>
                            </Link>
                         </li>   
                      </ul>
                   </div>
                </div>
    
                <div className="service-widget mb-50 border-0 p-0">
                   <div className="banner-widget">
                      <Link to="/contact">
                         <img src="img/services/services-banner.png" alt="" />
                      </Link>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </>
  )
}

export default ServicesDetailsArea;
