import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import Surgery from '../Surgery/Surgery'
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';

export const SurgeryDetail = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title="GENERAL SURGERY" subtitle="Details" />
    <Surgery/>
    <HomeFooter/>
</>
  )
}
export default SurgeryDetail;