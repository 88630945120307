import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../../components/Shared/Sidebar/Sidebar";
import useGlobalContext from "../../../../hooks/useGlobalContext";
import logo from "./ShridhamLogo1.png.png";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
// import { BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { FaEnvelope } from "react-icons/fa6";
import { FaBars } from "react-icons/fa6";
// import { FaSquareWhatsapp } from "react-icons/fa6";

const HomeHeader = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();
  return (
    <>
      <header>
        <div className="top-bar d-none d-md-block">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1 col-md-7 offset-md-1">
                <div className="header-info">
                  <span>
                    <TbDeviceLandlinePhone />
                    <a href="tel:07794-282131"> 07794-282131</a>
                  </span>
                  <span>
                    <FaEnvelope />
                    <a href="mailto:shridhamhospital@gmail.com">
                      {" "}
                      shridhamhospital@gmail.com
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-4">
                <div className="header-top-right-btn f-right">
                  <Link to="/BookAppoinment" className="primary_btn">
                    Make Appointment
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"
          }
        >
          <div className="container menu_wrapper ">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                <div className="logo pos-rel">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                <div className="header-right f-right">
                  <div className="header-lang f-right pos-rel d-none d-lg-block"></div>
                  <div className="header-social-icons f-right d-none d-xl-block fs-6">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/shridhammultispecialityhospital/"
                          target="_blank"
                        >
                          <FaFacebookSquare />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/hospitalshridham/"
                          target="_blank"
                        >
                          <FaInstagramSquare />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://youtube.com/@ShridhamHospital?si=-LJGnKsfzwHJsNpl"
                          target="_blank"
                        >
                          <BsYoutube />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="header__menu f-right">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/servicesDetails">Services</Link>
                      </li>

                      <li>
                        <Link to="#">Departments+</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/PreventiveMedi" target="_blank">
                              GENERAL MEDICINE
                            </Link>
                          </li>
                          <li>
                            <Link to="/Orthopedic" target="_blank">
                              ORTHOPEDIC CARE
                            </Link>
                          </li>
                          <li>
                            <Link to="/Gynecologist" target="_blank">
                              GYNECOLOGY CARE
                            </Link>
                          </li>
                          <li>
                            <Link to="/Surgery" target="_blank">
                              GENERAL SURGERY
                            </Link>
                          </li>
                          <li>
                            <Link to="/Radiology" target="_blank">
                              RADIOLOGY
                            </Link>
                          </li>
                          <li>
                            <Link to="/Pathology" target="_blank">
                              PATHOLOGY DEPARTMENT
                            </Link>
                          </li>
                          <li>
                            <Link to="/Physiotherapy" target="_blank">
                              PHYSIOTHERAPY
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="#">Doctors +</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/doctorDetails" target="_blank">
                              Dr.A Verma
                            </Link>
                          </li>
                          <li>
                            <Link to="/DrAkhilesh" target="_blank">
                              Dr.Akhilesh Tiwari
                            </Link>
                          </li>
                          <li>
                            <Link to="/DrDeepak" target="_blank">
                              Dr.Deepak Mishra
                            </Link>
                          </li>
                          <li>
                            <Link to="/DrMohAhmad" target="_blank">
                              Dr.Moh.Ahmad Khan
                            </Link>
                          </li>
                          <li>
                            <Link to="/DrRakesh" target="_blank">
                              Dr.Rakesh Kumar
                            </Link>
                          </li>
                          <li>
                            <Link to="/DrAnoop" target="_blank">
                              Dr.Shailja Gupta
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* <li><Link to="#/blogs">Blog</Link></li> */}
                      <li>
                        <Link to="/ShriDhamCollege">Our College</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className="side-menu-icon d-lg-none text-end">
                  <button
                    onClick={handleShow}
                    className="side-toggle border-0 bg-transparent fs-1"
                  >
                    <FaBars />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeHeader;
