import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import Gynecologist from '../Gynecologist/Gynecologist'
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';

export const GynecologistDetail = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title=" OBSTETRICS & 
    GYNECOLOGY CARE" subtitle="Details" />
    <Gynecologist/>
    <HomeFooter/>
</>
  )
}
export default GynecologistDetail;