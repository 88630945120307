import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import BlogsArea from './BlogsArea/BlogsArea';
import HomeFooter from '../Home/Home/HomeFooter/HomeFooter';

const Blogs = () => {
   return (
      <>
         <HomeHeader/>
         <CommonPageHeader title="Shridham Hospital Blogs" subtitle="Blogs" />
         <BlogsArea/>
         <HomeFooter/>
      </>
   );
};

export default Blogs;