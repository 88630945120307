import React, { useState } from 'react';
import CommonSectionArea from '../../../components/CommonSectionArea/CommonSectionArea';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import FrontGate from '../../../pages/Home/Home/HomeAboutArea/CenterImage.jpg'
import { FaPlay } from "react-icons/fa";

const AboutArea = () => {
   const [isOpen, setOpen] = useState(false);
   return (
      
      <>
         <ModalVideo channel='youtube' autoplay isOpen={isOpen}
            videoId="qlm2oGYDGZs" onClose={() => setOpen(false)} />

         <CommonSectionArea area_header={
            <div className="about-left-side pos-rel mb-30">
               <div className="about-front-img pos-rel">
                  <img src={FrontGate} alt="FrontGate" />
                  <button onClick={() => setOpen(true)} className="popup-video about-video-btn white-video-btn fs-1 bg-danger">
                  <FaPlay /></button>
               </div>
               <div className="about-shape">
                  <img src="img/about/about-shape.png" alt="" />
               </div>
            </div>
         } />
      </>
   );
};

export default AboutArea;