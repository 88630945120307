import React from 'react';

const CommonSectionArea = ({area_header}) => {
   return (
      <>
         <section className="about-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5">
                     {area_header}
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h5>About Us</h5>
                           <h1>Story About Shridham Hospital.</h1>
                        </div>
                        <div className="about-text mb-50">
                           <p>Welcome to our multispecialty hospital, where excellence in healthcare meets compassion. Our expert team of professionals ensures top-notch medical services across various specialties, including PREVENTIVE MEDICINE, ORTHOPEDIC, GYNECOLOGIST, GENERAL SURGERY, RADIOLOGY, PATHOLOGY, PHYSIOTHERAPY and more. With cutting-edge technology and personalized care, we are dedicated to your well-being. From routine check-ups to advanced treatments, our multispecialty hospital is committed to providing comprehensive healthcare solutions. Your health is our priority, and we strive for excellence in every aspect of your medical journey. Trust us for quality care and a healthier future.</p>
                        </div>
                        <div className="our-destination">
                           <div className="single-item mb-30">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-1.png" alt=""/>
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Mission</h3>
                                 <p>The mission of Shridham Hospital is to provide quality health services and facilities for the community, to promote wellness, to relieve suffering, and to restore health as swiftly, safely, and humanely as it can be done, consistent with the best service we can give at the highest value for all concerned.</p>
                              </div>
                           </div>
                           <div className="single-item">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-2.png" alt=""/>
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Vission</h3>
                                 <p>To provide each patient with the world-class care, exceptional service and compassion we would want for our loved ones and To Lead the evolution of healthcare to enable every member of the communities we serve to enjoy a better, healthier life.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonSectionArea;