import React from "react";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import ShriDhamCourses from "./ShriDhamCourses";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import ShriDhamCollegeHeroSection from "./ShriDhamCollegeHeroSection";
import ShriDhamCollegeAbout from "./ShriDhamCollegeAbout";
import ShriDhamCollegeFaculty from "./ShriDhamCollegeFaculty";
import ShriDhamCollegeHostal from "./ShriDhamCollegeHostal";
import ShriDhamCollegeLibrary from "./ShriDhamCollegeLibrary";
// import ShriDhamCollegeFooter from "./ShriDhamCollegeFooter";
// import ShriDhamCollegeHeader from "./ShriDhamCollegeHeader";

const ShriDhamCollege = () => {
  return (
    <>
      <>
        {/* <ShriDhamCollegeHeader />
         <ShriDhamCollegeFooter /> */}
        <HomeHeader />
        <ShriDhamCollegeHeroSection />
        <ShriDhamCollegeAbout />
        <ShriDhamCourses />
        <ShriDhamCollegeLibrary />
        <ShriDhamCollegeFaculty />
        <ShriDhamCollegeHostal />
        <HomeFooter />
      </>
    </>
  );
};

export default ShriDhamCollege;
