import React from "react";
import { Link } from "react-router-dom";
import Boys from "./BoysHostelCrop.jpeg";
import Girls from "./WhatsApp Image 2024-03-18 at 12.39.37 PM.jpeg";

const ShriDhamCollegeHostal = () => {
  return (
    <>
      <section className=" gray-bg mt-5">
        <div className="container">
          <div className="row">
            <div
              className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <div className="d-lg-flex  justify-content-center">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                  <h3 className="d-flex  justify-content-center me-1 mt-5 ">
                    <Link to="#">Boys Hostel</Link>
                  </h3>
                  <p className="fs-6 mb-4 mt-4">
                    "Experience comfort and convenience at our boys' hostel. Our
                    well-appointed facilities offer a secure and welcoming
                    environment conducive to academic success. Enjoy amenities
                    such as spacious rooms, recreational areas, and
                    round-the-clock security. With a focus on fostering a
                    supportive community, we ensure a memorable and enriching
                    college experience for every resident. Welcome to your home
                    away from home."
                  </p>
                </div>

                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-lg-3">
                  <div className="pos-rel ms-lg-5 ms-sm-0 mt-2 text-md-center">
                    <Link to="/">
                      <img
                        className="rounded-3"
                        src={Boys}
                        alt="logo"
                        style={{ width: "565px", height: "300px" }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12"
              data-aos="zoom-in-up"
              data-aos-duration="1500"
            >
              <div className="d-lg-flex  justify-content-center mb-5 mt-4 ">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-lg-3 ">
                  <div className="pos-rel ms-lg-5 ms-sm-0 text-md-center">
                    <Link to="/">
                      <img
                        className="rounded-3"
                        src={Girls}
                        alt="logo"
                        style={{ width: "565px", height: "300px" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <h3 className="mt-4 d-flex justify-content-center me-1 mt-md-5 ">
                    <Link to="#">Girls Hostel</Link>
                  </h3>
                  <p className="fs-6 mb-4 mt-4 ms-lg-5">
                    "Welcome to our girls' hostel, where safety and comfort
                    meet. Our modern facilities provide a nurturing environment
                    for academic and personal growth. Enjoy spacious rooms,
                    communal spaces, and amenities designed for your
                    convenience. With 24/7 security and supportive staff, we
                    prioritize your well-being and create a vibrant community.
                    Embrace the college experience with confidence in your home
                    away from home."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShriDhamCollegeHostal;
