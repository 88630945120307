import React from "react";
// import HomeSIngleService from "../../../../components/HomeSIngleService/HomeSIngleService";
// import icon from "./GnmNursing.png";
import { Link } from "react-router-dom";
import Course from "./community1.jpg";
const ShriDhamCourses = ({ border_class }) => {
  return (
    <>
      <section className="gray-bg mt-5">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <h2 className="d-flex justify-content-center ms-2 mt-5 mb-5">
                Streams To Pursue Your Passion
              </h2>
            </div>
            <div
              className={
                border_class
                  ? `service-box ${border_class} text-center mb-30`
                  : "service-box text-center"
              }
            >
              <div
                className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <div className="d-lg-flex  justify-content-center">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                    <h3 className="d-flex  justify-content-center me-1 mt-5 ">
                      <Link to="#">GNM Nursing</Link>
                    </h3>

                    <p className="fs-6 mb-4">
                      "Embark on a fulfilling journey in nursing with our GNM
                      program. Our comprehensive curriculum, hands-on clinical
                      training, and experienced faculty ensure a top-notch
                      education. Benefit from modern facilities, community
                      involvement, and extensive student support services. With
                      flexible learning options and financial aid opportunities,
                      we pave the way for your success in the dynamic field of
                      healthcare."
                    </p>
                  </div>

                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-md-5">
                    <div className="pos-rel ms-lg-5 ms-sm-0">
                      <Link to="/">
                        <img
                          className="rounded-3"
                          src={Course}
                          alt="logo"
                          style={{ width: "1000px" }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShriDhamCourses;
