import React from 'react'
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';
import DrAkhileshDetails from './Dr.Akhilesh Tiwari/DrAkhileshDetails';


export const Akhilesh = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title="Dr.Akhilesh Tiwari" subtitle="Details" />
    <DrAkhileshDetails />
    <HomeFooter/>
</>
  )
}
export default Akhilesh;