import React from 'react'
import { Link } from 'react-router-dom';
import { GiMicroscope } from "react-icons/gi";
import { GiMedicines } from "react-icons/gi";
import { FaXRay } from "react-icons/fa6";
import { TbPhysotherapist } from "react-icons/tb";
import { FaRegHospital } from 'react-icons/fa';
import {FaVenus} from 'react-icons/fa';
import Happy from'../orthopedics-1000x1000.webp'
import care from '../care.png';
import service from '../lifesupport.png';

export const Orthopedic = () => {
  return (
    <>
         <div className="service-details-area pt-120 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 col-lg-8">
                     <article className="service-details-box">
                        <div className="service-details-thumb mb-80">
                           <img className="img" src={Happy} alt="650*406" />
                        </div>
                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Orthopedic Care</h5>
                              <h1>We are here to help when you need us.</h1>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">
                           <p>All this and more is what Shridham Hospital. With cutting edge diagnostic facilities and treatment in orthopedics we aim to offer excellence in Complex Trauma Fractures joint replacement surgeries, hip resurfacing and is equipped to treat all types of musculo-skeletal problems for children, adults and elderly people, ranging from minimally invasive arthroscopic surgeries to complex trauma services.</p>

                           <p>Shridham Hospital is your one stop for complete bone and joint care providing solutions that vary from Complex Trauma Fractures Joint Replacements to Sports Medicine and other services to diagnose your problem at the roots and nip it before it gets worse! We believe in prevention and early diagnosis.</p>

                           <p>It is One Of The Best hospital who are offering Complete Orthopaedic Solutions under One roof in Narsinghpur District.</p>

                           <p>Few know about the advantages of regular exercise; being active is one of the best ways you can keep your bones and joints working well. Exercise helps you maintain bone density as you get older, lessen joint pain, keep off extra weight that can stress your joints and more importantly regular exercise helps you balance so you avoid falls that can damage bones and joints.</p>

                           <p>Take time off from your busy schedule to go biking or swimming with your family or friends, outdoor activities not only refresh your mind but help maintain a healthy heart and lungs conversely helping in bone and muscle strengthening.</p>

                           <p>Get at least 150 minutes of moderate activity every week, including some weight-bearing exercise. That amounts to about 30 minutes a day, 5 days a week, bearing in mind the hectic lifestyle you can find ways to build activity into your daily routine, whether it's household work or taking a brisk walk with a friend.</p>

                           <p>Make sure you combine flexibility exercise along with the daily workout regime, such as yoga and stretches that are also good for the joints.</p>
                        </div>
                        <div className="service-details-feature fix mb-35">
                           <div className="ser-fea-box f-left">
                              <div className="ser-fea-icon f-left">
                                 <img src={care} alt="care" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Personal care</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Comprehensive Health Assessments </li>
                                    <li><i className="fas fa-check"></i>Expert Medical Consultations</li>
                                    <li><i className="fas fa-check"></i>Efficient Coordination of Care</li>
                                    <li><i className="fas fa-check"></i>Patient-Centric Approach,</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="ser-fea-box f-left me-0">
                              <div className="ser-fea-icon f-left">
                                 <img src={service} alt="" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Lifestyle support</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Personalized Nutrition Guidance,</li>
                                    <li><i className="fas fa-check"></i>Fitness and Exercise Programs,</li>
                                    <li><i className="fas fa-check"></i>Behavioral Health Support,</li>
                                    <li><i className="fas fa-check"></i>Sleep Improvement Strategies.</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                           
                     </article>
                  </div>
                  <div className="col-xl-5 col-lg-4">
                     <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">More Services</h3>
                        </div>
                        <div className="more-service-list">
                           <ul>
                             <li>
                                 <Link to="/PreventiveMedi">
                                    <div className="more-service-icon fs-1 text-danger"><GiMedicines /></div>
                                    <div className="more-service-title">General Medicine</div>
                                 </Link>
                              </li>
                              
                              <li>
                                 <Link to="/Gynecologist">
                                    <div className="more-service-icon fs-1 text-danger"><FaVenus /></div>
                                    <div className="more-service-title">Gynecology Care</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Surgery">
                                    <div className="more-service-icon fs-1 text-danger"><FaRegHospital/></div>
                                    <div className="more-service-title">General Surgery</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Radiology">
                                    <div className="more-service-icon fs-1 text-danger"><FaXRay/></div>
                                    <div className="more-service-title">RADIOLOGY</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Pathology">
                                    <div className="more-service-icon fs-1 text-danger"><GiMicroscope /></div>
                                    <div className="more-service-title"> PATHOLOGY DEPARTMENT</div>
                                 </Link>
                              </li>      
                              
                              <li>
                                 <Link to="/Physiotherapy">
                                    <div className="more-service-icon fs-1 text-danger"><TbPhysotherapist /></div>
                                    <div className="more-service-title">PHYSIOTHERAPY </div>
                                 </Link>
                              </li>   
                           </ul>
                        </div>
                     </div>
         
                     <div className="service-widget mb-50 border-0 p-0">
                        <div className="banner-widget">
                           <Link to="/contact">
                              <img src="img/services/services-banner.png" alt="" />
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
  )
}
export default Orthopedic;