import React from 'react';

const ContactMap = () => {
    return (
        <>
            <section className="map-area mb-5 mt-5">

                    <div style={{ width: '100%' }}>
                        <iframe title="Contact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.6625310244067!2d79.4741454747706!3d23.036159915822047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3981d386a0f835f3%3A0xdc199cb891711cf5!2zU2hyaWRoYW0gSG9zcGl0YWwg4KS24KWN4KSw4KWA4KSn4KS-4KSuIOCkueClieCkuOCljeCkquCkv-Ckn-Cksg!5e0!3m2!1sen!2sin!4v1704376696318!5m2!1sen!2sin" style={{ width: '100%' }} height={600} allowFullScreen="" loading="lazy"></iframe>
                    </div>

            </section>
        </>
    );
};

export default ContactMap;