import React from 'react'
import { Link } from 'react-router-dom';
import { GiMicroscope } from "react-icons/gi";
import { GiMedicines } from "react-icons/gi";
import { FaXRay } from "react-icons/fa6";
import { TbPhysotherapist } from "react-icons/tb";
import { TbDisabled } from "react-icons/tb";
import { FaRegHospital } from 'react-icons/fa';
import {FaVenus} from 'react-icons/fa';

import care from '../care.png';
import service from '../lifesupport.png';
import Radio from './radiologist_1_746x465.jpg';

const data = [
    {
       name: 'Page A',
       uv: 4000,
       pv: 2400,
       amt: 2400,
    },
    {
       name: 'Page B',
       uv: 3000,
       pv: 1398,
       amt: 2210,
    },
    {
       name: 'Page C',
       uv: 2000,
       pv: 9800,
       amt: 2290,
    },
    {
       name: 'Page D',
       uv: 2780,
       pv: 3908,
       amt: 2000,
    },
    {
       name: 'Page E',
       uv: 1890,
       pv: 4800,
       amt: 2181,
    },
    {
       name: 'Page F',
       uv: 2390,
       pv: 3800,
       amt: 2500,
    },
    {
       name: 'Page G',
       uv: 3490,
       pv: 4300,
       amt: 2100,
    },
 ];

export const Radiology = () => {
  return (
    <>
         <div className="service-details-area pt-120 pb-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 col-lg-8">
                     <article className="service-details-box">
                        <div className="service-details-thumb mb-80">
                           <img className="img" src={Radio} alt="650*406" />
                        </div>
                        <div className="section-title pos-rel mb-45">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png" alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">RADIOLOGY</h5>
                              <h1>We are here to help when you need us.</h1>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-30">

                           <p>"The Radiology Department is a vital component of modern healthcare, employing advanced imaging technologies to diagnose and treat various medical conditions. Comprising skilled radiologists, technologists, and support staff, this department plays a crucial role in providing accurate and timely diagnostic information to aid clinicians in delivering optimal patient care.</p>

                           <p>State-of-the-art equipment, such as X-ray, CT scans, MRI, ultrasound, and nuclear medicine, is utilized to capture detailed images of internal structures, enabling healthcare professionals to assess the presence and extent of injuries, diseases, or abnormalities. The Radiology Department operates with a commitment to precision and efficiency, employing cutting-edge technology to ensure the highest quality diagnostic imaging.</p>

                           <p>Radiologists, experts in interpreting medical images, collaborate closely with referring physicians to provide comprehensive reports and guide appropriate treatment plans. Technologists, trained in operating sophisticated imaging equipment, prioritize patient comfort and safety while capturing high-quality images.</p>

                           <p>In addition to diagnostic services, the Radiology Department plays a pivotal role in interventional procedures, utilizing imaging guidance for minimally invasive treatments. This multifaceted approach underscores the department's dedication to advancing medical science and patient care.</p>

                           <p>The Radiology Department is committed to continuous improvement, staying abreast of technological advancements and evolving medical practices. This commitment ensures that patients receive the best possible diagnostic and therapeutic services, reinforcing the department's role as an indispensable component of the healthcare ecosystem."</p>

                        </div>
                        <div className="service-details-feature fix mb-35">
                           <div className="ser-fea-box f-left">
                              <div className="ser-fea-icon f-left">
                                 <img src={care} alt="care" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Personal care</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Comprehensive Health Assessments </li>
                                    <li><i className="fas fa-check"></i>Expert Medical Consultations</li>
                                    <li><i className="fas fa-check"></i>Efficient Coordination of Care</li>
                                    <li><i className="fas fa-check"></i>Patient-Centric Approach,</li>
                                 </ul>
                              </div>
                           </div>
                           <div className="ser-fea-box f-left me-0">
                              <div className="ser-fea-icon f-left">
                                 <img src={service} alt="" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3>Lifestyle support</h3>
                                 <ul>
                                    <li><i className="fas fa-check"></i>Personalized Nutrition Guidance,</li>
                                    <li><i className="fas fa-check"></i>Fitness and Exercise Programs,</li>
                                    <li><i className="fas fa-check"></i>Behavioral Health Support,</li>
                                    <li><i className="fas fa-check"></i>Sleep Improvement Strategies.</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                           
                     </article>
                  </div>
                  <div className="col-xl-5 col-lg-4">
                     <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">More Services</h3>
                        </div>
                        <div className="more-service-list">
                           <ul>
                             <li>
                                 <Link to="/PreventiveMedi">
                                    <div className="more-service-icon fs-1 text-danger"><GiMedicines /></div>
                                    <div className="more-service-title">General Medicine</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Orthopedic">
                                    <div className="more-service-icon fs-1 text-danger"><TbDisabled /></div>
                                    <div className="more-service-title">Orthopedic Care</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Gynecologist">
                                    <div className="more-service-icon fs-1 text-danger"><FaVenus /></div>
                                    <div className="more-service-title">Gynecology Care</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Surgery">
                                    <div className="more-service-icon fs-1 text-danger"><FaRegHospital/></div>
                                    <div className="more-service-title">General Surgery</div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/Pathology">
                                    <div className="more-service-icon fs-1 text-danger"><GiMicroscope /></div>
                                    <div className="more-service-title"> PATHOLOGY DEPARTMENT</div>
                                 </Link>
                              </li>      
                              
                              <li>
                                 <Link to="/Physiotherapy">
                                    <div className="more-service-icon fs-1 text-danger"><TbPhysotherapist /></div>
                                    <div className="more-service-title">PHYSIOTHERAPY </div>
                                 </Link>
                              </li>
                            
                           </ul>
                        </div>
                     </div>
                      
                     <div className="service-widget mb-50 border-0 p-0">
                        <div className="banner-widget">
                           <Link to="/contact">
                              <img src="img/services/services-banner.png" alt="" />
                           </Link>
                        </div>
                     </div> 
                  </div>
               </div>
            </div>
         </div>
      </>
  )
}
export default Radiology;