import React, { useEffect, useState } from "react";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import HomeFooter from "../../Home/Home/HomeFooter/HomeFooter";
import axios from "axios";
import cogoToast from "cogo-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TimepickerUI } from "timepicker-ui";
import { format } from "date-fns";

const BookAppoinment = () => {
  const [data, setData] = useState({
    dept: "",
    doctor: "",
    name: "",
    mobile: "",
    email: "",
    date: "",
    time: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setData({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleTimeChange = (e) => {
    const { hour, minutes, type } = e.detail;
    const newTime = `${hour}:${minutes} ${type}`;
    setData((prevData) => ({ ...prevData, time: newTime }));
  };

  const handleDateChange = (selectedDate) => {
    setData((prevData) => ({
      ...prevData,
      date: selectedDate,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const modifiedDate = new Date(data.date);
      modifiedDate.setDate(modifiedDate.getDate() + 1);
      const response = await axios.post(
        "https://shridhamhospitalgotegaon.com/api/auth/BookAppointment",
        {
          ...data,
          date: modifiedDate.toISOString().split("T")[0],
        }
      );
      console.log(response.data);
      cogoToast.success("messsage has been sent successfully");
      alert("messsage has been sent successfully");
      setData({
        dept: "",
        doctor: "",
        name: "",
        mobile: "",
        email: "",
        date: "",
        time: "",
        message: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(data);
  useEffect(() => {
    const tm = document.getElementById("timepicker-ui");
    const timepicker = new TimepickerUI(tm, {});
    timepicker.create();
    tm.addEventListener("accept", handleTimeChange);

    return () => {
      tm.removeEventListener("accept", handleTimeChange);
    };
  }, [handleTimeChange]);
  return (
    <>
      <HomeHeader />
      <section
        className="appointment-area appointment-area-3 appointment_page_bg pos-rel pt-115 pb-120"
        data-background="img/appoinment/appointment-bg.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8">
              <div className="calculate-box white-bg">
                <div className="calculate-content">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="about-title news-letter-title mb-70">
                        <h5 className="pink-color">Appointment</h5>
                        <h1>Book Appointment</h1>
                      </div>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <select
                          className="form-select select_style"
                          aria-label="Default select example"
                          name="dept"
                          value={data.dept}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="" disabled selected hidden>
                            Department
                          </option>
                          <option value="Cardiac Care">Cardiac Care</option>
                          <option value="Preventive Medicine">
                            Preventive Medicine
                          </option>
                          <option value="Diabetes Care">Diabetes Care</option>
                          <option value="Critical Care">Critical Care</option>
                          <option value="Orthopedic Care">
                            Orthopedic Care
                          </option>
                          <option value="Neurological Care">
                            Neurological Care
                          </option>
                          <option value="Gynecologist Care">
                            Gynecologist Care
                          </option>
                        </select>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <select
                          className="form-select select_style"
                          aria-label="Default select example"
                          name="doctor"
                          value={data.doctor}
                          onChange={handleInputChange}
                          required
                        >
                          <option value={"doctor"}>Doctor</option>
                          <option value="Dr.Akhilesh Tiwari">
                            Dr.Akhilesh Tiwari
                          </option>
                          <option value="Dr.Deepak Mishra">
                            Dr.Deepak Mishra
                          </option>
                          <option value="Dr.Moh.Ahmad Khan">
                            Dr.Moh.Ahmad Khan
                          </option>
                          <option value="Dr.Rakesh Kumar">
                            Dr.Rakesh Kumar
                          </option>
                          <option value="Dr.Shailja Gupta">
                            Dr.Shailja Gupta
                          </option>
                          <option value="Dr.A Verma">Dr.A Verma</option>
                        </select>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div
                          className="calculate-form appointment-form-3 mb-20"
                          action="#"
                        >
                          <input
                            type="text"
                            placeholder="Your Full Name"
                            name="name"
                            value={data.name}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div
                          className="calculate-form appointment-form-3 mb-20"
                          action="#"
                        >
                          <input
                            type="text"
                            placeholder="Your Phone number"
                            name="mobile"
                            value={data.mobile}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-6">
                        <div
                          className="calculate-form appointment-form-3 mb-20"
                          action="#"
                        >
                          <input
                            type="email"
                            placeholder="Your Email"
                            name="email"
                            value={data.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div class="calculate-form appointment-form-3 mb-20">
                          <DatePicker
                            selected={data.date}
                            onChange={(date) => handleDateChange(date)}
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Select a date"
                            value={data.date}
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            scrollableYearDropdown={false}
                          />
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div
                          className="calculate-form appointment-form-3 mb-20"
                          action="#"
                          id="timepicker-ui"
                        >
                          <input
                            type="text"
                            name="time"
                            className="timepicker-ui-input"
                            value={data.time}
                            onChange={handleInputChange}
                            placeholder="Time"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-xl-12">
                        <div
                          className="calculate-form appointment-form-3 mb-20"
                          action="#"
                        >
                          <textarea
                            cols="30"
                            rows="10"
                            placeholder="Special request"
                            name="message"
                            value={data.message}
                            onChange={handleInputChange}
                            required
                          ></textarea>
                        </div>
                      </div>
                      <div>
                        <button type="submit" className="primary_btn mt-40">
                          submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <HomeFooter />
    </>
  );
};

export default BookAppoinment;
