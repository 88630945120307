import React from "react";
import FrontGateCollege from "../ShriDhamCollege/NursingCollegeShridham.png";
import Founder from "../Home/Home/HomeAboutArea/Picsart_23-06-30_16-44-17-698_80x80.jpg";

const ShriDhamCollegeAbout = () => {
  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 about_left d-flex align-items-center">
              <div className="medical-icon-brand-2">
                <img src="img/about/medical-brand-icon-border.png" alt="" />
              </div>

              <div
                className="about-left-side pos-rel mb-20"
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                <div className="about-front-img">
                  <img src={FrontGateCollege} alt="" className="rounded-3" />
                </div>
                <div className="about-shape">
                  <img src="img/about/about-shape.png" alt="" />
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-7"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="about-right-side pt-55 mb-30">
                <div className="about-title mb-20">
                  <h5>About Us</h5>
                  <h1>Story About Shridham College.</h1>
                </div>
                <div className="about-text">
                  <p>
                    Welcome to Shridham College of Nursing, where we are devoted
                    to advancing the field of General Nursing and Midwifery
                    (GNM) through a student-centric approach, innovative
                    teaching methodologies, and a heritage of academic
                    distinction. As a leading institution in GNM education, we
                    take pride in our esteemed legacy, unwavering commitment to
                    quality nursing education, and continuous pursuit of
                    innovation. This narrative will elucidate the essence of
                    Shridham College of Nursing, encompassing our journey,
                    mission, values, and the profound impact we endeavor to make
                    in the lives of our GNM students and the communities they
                    serve.
                  </p>
                  <h3>Our Legacy and Journey:</h3>
                  <p>
                    Established in 2020, Shridham College of Nursing emerged as
                    a visionary endeavor aimed at creating an educational
                    institution that surpasses conventional standards in the
                    field of General Nursing and Midwifery (GNM). Since our
                    inception, our journey has been characterized by significant
                    milestones that underscore our dedication to delivering
                    comprehensive, state-of-the-art nursing education services.
                    From our modest origins, we have evolved into a renowned
                    institution, distinguished for our excellence in academics,
                    research, and community outreach, specifically tailored to
                    the GNM nursing profession.
                  </p>{" "}
                  <br />
                </div>
                <div className="about-author d-flex align-items-center rounded-circle">
                  <div className="author-ava ">
                    <img src={Founder} alt="" className="rounded-circle" />
                  </div>
                  <div className="author-desination">
                    <h4>Rohit Bilwar</h4>
                    <h6>Founder</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShriDhamCollegeAbout;
