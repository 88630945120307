import React from "react";
import { Link } from "react-router-dom";
import librayimg from "./WhatsApp Image 2024-03-18 at 11.51.21 AM.jpeg";
const ShriDhamCollegeLibrary = ({ border_class }) => {
  return (
    <>
      <section className="gray-bg mt-5">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <h2 className="d-flex justify-content-center ms-2 mt-5 mb-5">
                WE THE CHANGE YOU WANT TO SEE IN THE WORLD
              </h2>
            </div>
            <div
              className={
                border_class
                  ? `service-box ${border_class} text-center mb-30`
                  : "service-box text-center"
              }
            >
              <div
                className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12"
                data-aos="zoom-in-up"
                data-aos-duration="1500"
              >
                <div className="d-lg-flex  justify-content-center">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-lg-3">
                    <div className="pos-rel ms-lg-5 ms-sm-0 mt-5">
                      <Link to="/">
                        <img
                          className="rounded-3"
                          src={librayimg}
                          alt="logo"
                          style={{ width: "1000px" }}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                    <h3 className="d-flex  justify-content-center me-1 mt-5 ">
                      <Link to="#">Library</Link>
                    </h3>

                    <p className="fs-6 mb-4 mt-4 ms-lg-3">
                      "Welcome to our library, a haven for academic pursuit and
                      intellectual growth. Explore our vast collection of books,
                      journals, and digital resources spanning various
                      disciplines. Whether you seek quiet study spaces,
                      collaborative areas, or research assistance, our library
                      offers it all. Knowledgeable staff are available to
                      support your information needs and facilitate your
                      academic journey. Immerse yourself in a world of knowledge
                      and unlock the doors to endless learning possibilities."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShriDhamCollegeLibrary;
