import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope } from "react-icons/fa";
import { MdAddLocation } from "react-icons/md";
import { TbDeviceLandlinePhone } from "react-icons/tb";
const ContactArea = () => {
    return (
        <>
            <section className="contact-area pt-120 pb-90" data-background="assets/img/bg/bg-map.png">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                            <div className='text-danger mt-4 mb-5'><a className='text-danger'  href="mailto:shridhamhospital@gmail.com"><FaEnvelope className='fs-1'/></a></div>
                                <h3>Mail Here</h3>
                              <p><a href="mailto:shridhamhospital@gmail.com">shridhamhospital@gmail.com</a></p>

                                <p><a href="mailto:hospitalshridham@gmail.com"> hospitalshridham@gmail.com</a></p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">  
                            <div className='fs-1 text-danger mt-4 mb-5'><MdAddLocation className='fs-1'/></div>
                                <h3>Visit Here</h3>
                                 <p><Link to="/ContactMap"> shridham(Gotegaon), Narsinghpur Madhya Pradesh</Link></p>
                            </div>
                        </div>
                        <div className="col-xl-4  col-lg-4 col-md-4 ">
                            <div className="contact text-center mb-30">
                            <div className='fs-1 text-danger mt-4 mb-5'><a className='text-danger' href="tel:+91 7224900285"><TbDeviceLandlinePhone /></a></div>
                                <h3>Call Here</h3>
                                <p><a href="tel:07794-282131">07794-282131</a></p>
                                <p><a href="tel:+91 7794282131">+91 7794282131</a></p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactArea;