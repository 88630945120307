import React from "react";
import Slider from "react-slick";
import VideoPopup from "../../components/VideoPopup/VideoPopup";
import useGlobalContext from "../../hooks/useGlobalContext";
import ShriDhamSingleSlider from "./ShriDhamSingleSlider";

const ShriDhamCollegeHeroSection = () => {
  const { SlickArrowLeft, SlickArrowRight } = useGlobalContext();

  const settings = {
    autoplay: false,
    autoplaySpeed: 10000,
    dots: false,
    fade: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <VideoPopup videoId="QNIZ-NotlYo" />

      <section className="hero-area">
        <div className="hero-slider">
          <Slider className="slider-active" {...settings}>
            <ShriDhamSingleSlider className="1" />

            <ShriDhamSingleSlider className="2" />
          </Slider>
        </div>
      </section>
    </>
  );
};

export default ShriDhamCollegeHeroSection;
