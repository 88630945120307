import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';
import DrMohAhmadDetails from './Dr.Moh.Ahmad Khan/DrMohAhmadDetails';

export const DrMohAhmad = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title="Dr.Moh.Ahmad Khan" subtitle="Details" />
    <DrMohAhmadDetails/>
    <HomeFooter/>
</>
  )
}
export default DrMohAhmad;