import React from "react";
import { Link } from "react-router-dom";
// import logo from "../HomeHeader/ShriDhamLogo.png.png";
import { FaEnvelope } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { TbDeviceLandlinePhone } from "react-icons/tb";
// import { FaFacebookSquare } from "react-icons/fa";
// import { FaInstagramSquare } from "react-icons/fa";
// import { BsLinkedin } from "react-icons/bs";
// import { BsYoutube } from "react-icons/bs";

const ShriDhamCollegeFooter = () => {
  return (
    <>
      <footer>
        <div className="footer-top primary-bg pt-115 pb-90 mt-lg-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6 col-md-8 mt-lg-5">
                <div className="footer-contact-info mb-30">
                  <div className="emmergency-call fix">
                    <div className="emmergency-call-icon f-left">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="emmergency-call-text f-left">
                      <h6 className="ms-5">Contact Number</h6>
                      <span className="me-1 text-primary">
                        <TbDeviceLandlinePhone />
                        <a href="tel:07794-282131" className="text-primary">
                          {" "}
                          07794-282131
                        </a>
                      </span>
                    </div>
                  </div>

                  <div className="footer-contact-content fw-medium mb-25">
                    <Link to="#">
                      Ambulances serve as the first responders to medical
                      emergencies, including accidents, injuries, sudden
                      illnesses, and other critical situations.
                    </Link>
                  </div>
                  <div className="footer-emailing">
                    <ul>
                      <li>
                        <FaEnvelope className="text-dark" />
                        <Link to="mailto:shridhamhospital@gmail.com">
                          {" "}
                          shridhamhospital@gmail.com
                        </Link>
                      </li>

                      <li>
                        <FaEnvelope className="text-dark" />
                        <Link to="mailto:hospitalshridham@gmail.com">
                          {" "}
                          hospitalshridham@gmail.com
                        </Link>
                      </li>
                      <li>
                        <FaLocationDot className="text-dark" />
                        <Link to="/ContactMap">
                          Shridham(Gotegaon),Narsinghpur
                        </Link>
                      </li>
                      <li className="ms-4">
                        <Link to="/ContactMap">Madhya Pradesh</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                <div className="footer-widget mb-30">
                  <div className="footer-title">
                    <h3>Departments</h3>
                  </div>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="/PreventiveMedi" target="_blank">
                          GENERAL MEDICINE
                        </Link>
                      </li>
                      <li>
                        <Link to="/Orthopedic" target="_blank">
                          ORTHOPEDIC CARE
                        </Link>
                      </li>
                      <li>
                        <Link to="/Gynecologist" target="_blank">
                          GYNECOLOGIST CARE
                        </Link>
                      </li>
                      <li>
                        <Link to="/Surgery" target="_blank">
                          GENERAL SURGERY
                        </Link>
                      </li>
                      <li>
                        <Link to="/Radiology" target="_blank">
                          RADIOLOGY
                        </Link>
                      </li>
                      <li>
                        <Link to="/Pathology" target="_blank">
                          PATHOLOGY DEPARTMENT
                        </Link>
                      </li>
                      <li>
                        <Link to="/Physiotherapy" target="_blank">
                          PHYSIOTHERAPY
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 offset-xl-1 col-lg-3 d-md-none d-lg-block">
                <div className="footer-widget mb-30">
                  <div className="footer-title">
                    <h3>Quick Links</h3>
                  </div>

                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="/Founder" target="_blank">
                          OUR FOUNDER
                        </Link>
                      </li>
                      {/* <li><Link to="/blogs" target="_blank">Blogs</Link></li> */}
                      <li>
                        <Link to="/contact" target="_blank">
                          CONTACT US
                        </Link>
                      </li>
                      <li>
                        <Link to="/BookAppoinment" target="_blank">
                          BOOK AN APPOINTMENT
                        </Link>
                      </li>
                      <li>
                        <Link to="/ContactFormArea" target="_blank">
                          ESTIMATE YOUR IDEA
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="footer-title">
                    <h3>Follow Us On</h3>
                  </div>
                    <div className=" fs-6;">
                      <ul>
                        <li className="fs-4">
                          <Link
                            to="https://www.facebook.com/shridhammultispecialityhospital/"
                            target="_blank"
                          >
                            <FaFacebookSquare />
                          </Link>
                        </li>
                        <li className="fs-4">
                          <Link
                            to="https://www.instagram.com/hospitalshridham/"
                            target="_blank"
                          >
                            <FaInstagramSquare />
                          </Link>
                        </li>

                        <li className="fs-4 ">
                          <Link
                            to="https://youtube.com/@ShridhamHospital?si=-LJGnKsfzwHJsNpl"
                            target="_blank"
                          >
                            <BsYoutube />
                          </Link>
                        </li>
                      </ul>
                    </div>  */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom pt-25 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-copyright text-center">
                  <p className="white-color">
                    {" "}
                    © Copyright by @ DoaGuru Infosystem 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ShriDhamCollegeFooter;
