import React from 'react'
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import Pathology from '../Pathology/Pathology'
import HomeFooter from '../../Home/Home/HomeFooter/HomeFooter';

export const PathologyDetail = () => {
  return (
    <>
    <HomeHeader/>
    <CommonPageHeader title="PATHOLOGY DEPARTMENT" subtitle="Details" />
    <Pathology/>
    <HomeFooter/>
</>
  )
}
export default PathologyDetail;